import React, { useState, useEffect } from 'react';
import MainRouter from './router/MainRouter';
import { Provider } from 'react-redux';
import { emptyObject } from './functions/functions';
import { fetchAxios } from './functions/axiosRequests';
import { configureAppStore } from './redux/configureStore';
import config from './config/envConfig';
import { LAYOUT } from './config/dictionaries';
import { v4 as uuid } from 'uuid';
import appConfig from './config/appConfig';
import auth from './functions/auth-helper';

const Main = () => {
    const [store, setStore] = useState({});

    useEffect(() => {
        const tabId = uuid();
        sessionStorage.setItem('tabId', tabId);
        initApp();
    }, [])

    
    const initApp = async () => {
        let initState = {};
        const url = `${config.serverLink}/api/init`;
        await fetchAxios(url)
        .then(function (response) {
            const { defaults, colorScaleRange, measures, regions, regionOptions, strainSubsetOptions, strainHighlightOptions, missingDataColor, INSTANCE_ID } = response.data;

            appConfig.setDefault(defaults);
            appConfig.setColorScaleRange(colorScaleRange);
            appConfig.setMeasures(measures);
            appConfig.setRegions(regions);
            appConfig.setRegionOptions(regionOptions);
            appConfig.setStrainSubsetOptions(strainSubsetOptions);
            appConfig.setStrainHighlightOptions(strainHighlightOptions);
            appConfig.setMissingDataColor(missingDataColor);

            initState = response.data.initState;
            const predictionBaseline = new Date(initState.parameters.predictionBaseline).toISOString();
            initState.parameters.layout = LAYOUT.TREE.value;
            initState.parameters.predictionBaseline = predictionBaseline;
            initState.settings.parameters.predictionBaseline = predictionBaseline;
            initState.settings.parameters.predictionBaseline = predictionBaseline;

            auth.setInstanceId(INSTANCE_ID);
        })
        .catch(function (error) {
            console.log(error)
        });
        const configuredStore = configureAppStore(initState);
        setStore(configuredStore);
    }
    return (
        <>
        { !emptyObject(store) &&
            <Provider store={store}>
                <MainRouter />
            </Provider>
        }
        </>
    )
}

export default Main;

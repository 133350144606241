import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { LogoIconDesktop, LogoIconMobile } from '../components/SvgIcons/LogoIcon';
import MenuButton from './MenuButton';
import UserMenu from './userMenu/UserMenu';
import { signOut } from '../redux/actions/userActions';
import { getIsMobile } from '../redux/selectors/statusSelector';
import { setHiddenMenu, setHiddenMenuMobile } from '../redux/actions/renderActions';
import auth from '../functions/auth-helper';
import LineageSelector from '../components/OptionsSelector/LineageNewSelectorro';
import config from '../config/envConfig';
import OutsideLinks from './OutsideLinks/OutsideLinks';

const styles = {
    root: {
        boxShadow: 'none',
        zIndex: 10,
        position: 'relative',
        // borderBottom: '20px solid #e2e2e2',
        overflowY: 'none',
    },
    logoButton: {
        marginLeft: -12,
        marginRight: 20,
        display: 'flex',
        color: '#EEEEEE',
        '&:hover': {
            color: '#FFFFFF',
            boxShadow: 'none',
            borderColor: 'white',
        },
    },
    menuButton: {
        marginLeft: 20,
        marginRight: 0,
        width: 97,
        hight: 32,
        display: 'flex',
        padding: '2px',
        maxHeight: 32,
        minHeight: 32,
        color: '#000000',
        boxShadow: 'none',
        transition: 'unset !important',
    },
    grow: {
        flexGrow: 1,
    },
    mobileMenu: {
        backgroundColor: 'black',
        // display: 'flex',
        padding: '10px 5px 10px 0px',
        fontSize: 10
    },
    desktopLinkMenu: {
        backgroundColor: '#323232',
        fontSize: '12px',
        height: '71px',
        paddingLeft: '6px'
    },
    leftButtons: {
        width: '90%',
        display: 'flex',
        marginTop: '4px'
    },
    links: {
        margin: '0px',
        display: 'flex'
    },
    linksDesktop: {
        marginTop: '15px',
        display: 'flex'
    },
    line: {
        backgroundColor: 'white',
        marginTop: '5px',
        marginRight: '8px',
    },
    lineMobile: {
        backgroundColor: 'white',
        marginTop: '1px',
        marginRight: '8px',
    },
    lineButtons: {
        marginTop: '-5px',
        backgroundColor: '#B5B2B2',
        marginLeft: '40px',
        marginRight: '10px',
        height: '100%'
    },
    smallTextDesktop: {
        marginTop: '15px',
        height: '40px',
        fontSize: '14px',
        lineHeight: '14px'
    },
    smallTextMobile: {
        marginTop: '0px',
        height: '20px',
        fontSize: '11px',
        lineHeight: '11px'
    },
    openMenuMobile: {
        position: 'absolute',
        marginLeft: '80vw',
        marginTop: '1vh'
    }
};

const Header = (props) => {
    const { history, adminRights, dataAdminRights, classes, setHiddenMenuMobile, modules, signOut, isAuthenticated, name, permissionTabs, isMobile, setHiddenMenu, hiddenMenu, hiddenMenuMobile } = props;

    const location = useLocation();
    const { pathname } = location;
    const isExportExpr = `${config.frontendPrefix}/export`;
    const isExportPath = pathname.startsWith(isExportExpr);

    const handleMenuOpenMobile = () => {
        setHiddenMenuMobile({ hiddenMenuMobile: !hiddenMenuMobile })
    }
    const userAuthenticated = isAuthenticated && auth.isAuthenticated();
    // console.log(`[Header] userAuthenticated = ${userAuthenticated}, ${isAuthenticated}, ${auth.isAuthenticated()}`)
    const RouterLink = React.forwardRef((linkProps, ref) => <Link ref={ref} to={`${config.frontendPrefix}/`} {...linkProps} />);
    return (
        !isExportPath && (
            <AppBar position="static" className={classes.root}>
                <Toolbar style={{ backgroundColor: '#5D5A5A', minHeight: isMobile ? '46px' : '60px', paddingRight: '21px', paddingLeft: '24px'}}>
                    <IconButton className={classes.logoButton} component={RouterLink} color="inherit" aria-label="Menu">
                        {isMobile ? <LogoIconMobile /> : <LogoIconDesktop />}
                    </IconButton>
                    <svg className={isMobile ? classes.lineMobile : classes.line} xmlns="http://www.w3.org/2000/svg" width="1" height="35" viewBox="0 0 1 35" fill="none">
                        <path d="M1 0L1 35" stroke="white" />
                    </svg>
                    <div className={isMobile ? classes.smallTextMobile : classes.smallTextDesktop}>
                        Predictive analysis <br /> of viral evolution
                    </div>
                    <div className={classes.grow} />
                    {isMobile ?
                        <>
                            {
                                userAuthenticated ?
                                    <UserMenu signOut={signOut} history={history} adminRights={adminRights} dataAdminRights={dataAdminRights} name={name} />
                                    :
                                    <OutsideLinks />
                            }
                        </>
                        :
                        <>
                            <MenuButton label="Data sources" internalLink="#data_sources" variant="text" />
                            <MenuButton label="Guide" externalLink="https://previr.com/analysis#previr-app" variant="text" />
                            <MenuButton label="Previr Center" externalLink="https://previr.com/" variant="text" />
                            {
                                userAuthenticated && (pathname === '/settings' || modules) ?
                                    <UserMenu signOut={signOut} history={history} adminRights={adminRights} dataAdminRights={dataAdminRights} name={name} />
                                    :
                                    <MenuButton label="Sign in" routeName="signin" defaultOption variant="text" />
                            }
                        </>
                    }
                </Toolbar>
                {
                    pathname !== '/signin' ? isMobile ?
                        <div className={classes.mobileMenu}>
                            {pathname !== '/signin' && <LineageSelector />}
                            <div className={classes.leftButtons}>
                                <div className={classes.links}>
                                    {
                                        userAuthenticated && modules ?
                                            <>
                                                {modules.strainTree && permissionTabs.strainTree && <MenuButton label="Tree" routeName="" defaultOption variant="text" />}
                                                {modules.frequencies && permissionTabs.frequencies && <MenuButton label="Frequencies" routeName="frequencies" variant="text" />}
                                                {modules.antigenic && permissionTabs.antigenic && <MenuButton label="Antigenic" routeName="antigenic" variant="text" />}
                                                {modules.antigenic && permissionTabs.fitness && <MenuButton label="Antigenic" routeName="fitness" variant="text" />}
                                                {modules.geomap && permissionTabs.map && <MenuButton label="Map" routeName="geomap" variant="text" />}
                                            </>
                                            :
                                            <>
                                                <MenuButton label="Tree" routeName="intro" defaultOption variant="text" />
                                                <MenuButton label="Frequencies" routeName="intro-freq" defaultOption variant="text" />
                                            </>
                                    }
                                </div>
                            </div>
                            <IconButton className={classes.openMenuMobile} onClick={handleMenuOpenMobile} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="47" viewBox="0 0 46 47" fill="none">
                                    <g filter="url(#filter0_d_5_3805)">
                                        <ellipse cx="23" cy="19.4218" rx="19" ry="19.045" fill="#F1F1F1" />
                                        <path d="M41.5 19.4218C41.5 29.665 33.2162 37.9668 23 37.9668C12.7838 37.9668 4.5 29.665 4.5 19.4218C4.5 9.17854 12.7838 0.87677 23 0.87677C33.2162 0.87677 41.5 9.17854 41.5 19.4218Z" stroke="#DCDCDC" />
                                    </g>
                                    <line x1="17" y1="15.4147" x2="23" y2="15.4147" stroke="#636262" strokeWidth="2" strokeLinecap="round" />
                                    <line x1="24" y1="23.4336" x2="30" y2="23.4336" stroke="#636262" strokeWidth="2" strokeLinecap="round" />
                                    <path d="M30.35 15.4123C30.35 16.7156 29.2964 17.7694 28 17.7694C26.7036 17.7694 25.65 16.7156 25.65 15.4123C25.65 14.1091 26.7036 13.0552 28 13.0552C29.2964 13.0552 30.35 14.1091 30.35 15.4123Z" stroke="#636262" strokeWidth="1.3" />
                                    <path d="M21.35 23.4313C21.35 24.7345 20.2964 25.7884 19 25.7884C17.7036 25.7884 16.65 24.7345 16.65 23.4313C16.65 22.128 17.7036 21.0742 19 21.0742C20.2964 21.0742 21.35 22.128 21.35 23.4313Z" stroke="#636262" strokeWidth="1.3" />
                                    <defs>
                                        <filter id="filter0_d_5_3805" x="0" y="0.37677" width="46" height="46.09" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_3805" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_3805" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </IconButton>
                        </div>
                        :
                        <div className={classes.desktopLinkMenu}>
                            <div className={classes.leftButtons}>
                                <LineageSelector />
                                <svg className={classes.lineButtons} xmlns="http://www.w3.org/2000/svg" width="1" height="72" viewBox="0 0 1 72" fill="#B5B2B2">
                                    <path d="M1 0L1 72" stroke="#B5B2B2" />
                                </svg>
                                <div className={isMobile ? classes.links : classes.linksDesktop}>
                                    {userAuthenticated && modules ?
                                        <>
                                            {modules.strainTree && permissionTabs.strainTree &&
                                                <MenuButton label="Tree" routeName="" defaultOption variant="text" bottom={true} />
                                            }
                                            {modules.frequencies && permissionTabs.frequencies &&
                                                <MenuButton label="Frequencies" routeName="frequencies" variant="text" bottom={true} />
                                            }
                                            {modules.antigenic && permissionTabs.antigenic &&
                                                <MenuButton label="Antigenic" routeName="antigenic" variant="text" bottom={true} />
                                            }
                                            {modules.fitness && permissionTabs.fitness &&
                                                <MenuButton label="Fitness" routeName="fitness" variant="text" bottom={true} />
                                            }
                                            {modules.geomap && permissionTabs.map &&
                                                <MenuButton label="Map" routeName="geomap" variant="text" bottom={true} />
                                            }
                                        </>
                                        :
                                        <>
                                            <MenuButton label="Tree" routeName="intro" defaultOption variant="text" bottom={true} />
                                            <MenuButton label="Frequencies" routeName="intro-freq" defaultOption variant="text" bottom={true} />
                                            <MenuButton label="Map" routeName="intro-map" defaultOption variant="text" bottom={true} />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        : 
                        <></>
                }
            </AppBar>
        )
    );
};

Header.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }),
    classes: PropTypes.shape({ root: PropTypes.string, grow: PropTypes.string, logoButton: PropTypes.string }),
    modules: PropTypes.shape({
        strainTree: PropTypes.bool,
        predictions: PropTypes.bool,
        clades: PropTypes.bool,
        antigenic: PropTypes.bool,
    }),
    isAuthenticated: PropTypes.bool,
    signOut: PropTypes.func,
};
const mapStateToProps = ({ routes, parameters, metadata, user, render }) => ({
    ...routes,
    modules: metadata.modules,
    isAuthenticated: user.isAuthenticated,
    adminRights: user.permissions.adminRights,
    dataAdminRights: user.permissions.dataAdminRights,
    name: user.name,
    permissionTabs: user.permissions.tabs,
    hiddenMenu: render.hiddenMenu,
    hiddenMenuMobile: render.hiddenMenuMobile,
    isMobile: getIsMobile()
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ signOut, setHiddenMenu, setHiddenMenuMobile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));

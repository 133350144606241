import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Autocomplete } from '@mui/material';

import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { setParameters } from '../../redux/actions/parametersActions';
import { resetGenotypeStatus, fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';

import { emptyObject } from '../../functions/functions';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        width: '100%'
    },
    formControl: {
        minWidth: 120,
        margin: '0px 0px',
    },
    field: {
        width: '100%',
        margin: '12px 0px 0px 0px',
    },
    selectEmpty: {
        marginTop: 0
    },
    autocompleteInput: {
        margin: 0,
        padding: 0,
        '&>div': {
            height: '33px'
        }
    }
});


const MutPositionSearch = (props) => {

    const { lineage, mutgene, mutPositions,
        lineageStatus, mutationsPositionsDictStatus,
        setParameters, resetData, resetGenotypeStatus, fetchMutationsPositionsDictionary } = props;
    
    const geneMutPositions = useMemo(() => {
        const positions = mutPositions[mutgene] || [];
        return positions.length === 0
            ? positions
            : [{ label: '(none)', key: '' },
            ...positions.map(key => ({ label: key, key }))]
    }, [mutPositions, mutgene]);

    const [error, setError] = useState(false);
    const [errorTxt, setErrorTxt] = useState();
    const [positionList, setPositionList] = useState(geneMutPositions);
    const [disabled, setDisabled] = useState(false);
    // state = {
    //     error: false,
    //     errorTxt: null,
    //     positionList: [],
    //     disabled: false
    // }
    // const positionListInit = useRef(['']);
    //  _isMounted = false;

 

    useEffect(() => {
        // if (lineageStatus !== 'loaded') return;
        if (mutationsPositionsDictStatus !== 'loaded') return
        // if (shouldFetch(mutationsPositionsDictStatus))
        //     fetchMutationsPositionsDictionary({ lineage })
        // else if (mutationsPositionsDictStatus === 'loaded') 
        
        preparePositionList();

    }, [/*lineageStatus,*/ mutationsPositionsDictStatus, mutgene]);




    const preparePositionList = () => {

        // const { mutations } = treeAttrs;

        // this._isMounted = true;

        if (emptyObject(mutPositions)) {
            setError(true);
            setErrorTxt('No mutations for this lineage');
            setDisabled(true);
            return;
        }

        //const positionList = ['', ...(mutPositions[mutgene]||[])];

        //console.log('[preparePositionList]', mutgene, mutPositions[mutgene])
        // positionListInit.current = positionList;


        setPositionList(geneMutPositions);
        setDisabled(false);
        setError(false);
        setErrorTxt(null);
    }

    const handleMutPositionChange = async (event, value, reason) => {
        // console.log('1. handleMutPositionChange', value.key) //, positionList, positionList.includes(value));

        setParameters({
            mutposition: value && value.key && value.key.length > 0 ? value.key : null,
        })

        resetGenotypeStatus();
        if (resetData) {
            resetData();
        }
    }




    const { classes, label, mutposition, intro } = props;

    // console.log('[MutPositionSearch]: value', mutposition, 'options', positionList)

    // console.log('MutPositionSearch', mutposition, positionList, positionList.includes(`${mutposition}`));
    return (
        <div className={classes.root}>
            <Autocomplete
                id="mutPositionSearch"
                className={classes.field}
                options={positionList}
                getOptionLabel={(option) => { return typeof option === 'number' ? `${option}` : option.label }}
                isOptionEqualToValue={(option, value) => { return option.key == value }}
                // isOptionEqualToValue={(option, value) => {
                //     console.log(value, ' / ', option, option.key == value)
                //     return /*value.key ?*/ `${option.key}` === `${value}` /*: option.key == ''*/
                // }}
                //getOptionLabel={option => option.length ? `${option}` : ''}
                // getOptionLabel={(option) => { console.log('label', option); return `${JSON.stringify(option)}`}}
                disabled={disabled}
                autoSelect={true}
                value={mutposition}
                // onInputChange={handlePositionInputChange}
                onChange={handleMutPositionChange}
                disableClearable
                //defaultValue={null}
                renderInput={(params) => (
                    <StyledTextField {...params}
                        className={classes.autocompleteInput}
                        label={label}
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />

            {error && (<div style={{ color: 'red' }}>{errorTxt}</div>)}
        </div>
    );
};
// }

MutPositionSearch.propTypes = {
    treeAttrs: PropTypes.shape({ id: PropTypes.shape({}) }),
    classes: PropTypes.shape({ root: PropTypes.string, field: PropTypes.string }),
    label: PropTypes.string,
};

const mapStateToProps = ({ parameters, genotype, treeData, lineages }) => ({
    treeAttrs: treeData.treeAttrs,
    colorBy: parameters.colorBy,
    lineage: parameters.lineage,
    mutposition: parameters.mutposition,
    mutgene: parameters.mutgene,
    intro: parameters.intro,
    lineageStatus: lineages.lineageStatus,
    mutPositions: genotype.mutPositions,
    mutationsPositionsDictStatus: genotype.mutationsPositionsDictStatus
});

const mapDispatchToProps = {
    setParameters,
    resetGenotypeStatus,
    fetchMutationsPositionsDictionary
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MutPositionSearch));
